import Layout from "components/Layout";
import Page from "components/Page";
import SEO from "components/Seo";
import React from "react";

const CaseStudyTemplate = props => {
  const dataNode = props.pageContext.dataNode;
  return (
    <Layout>
      <SEO
        // metaDescription={dataNode.pageDescription}
        title={dataNode.pageTitle}
      />
      <Page data={dataNode} />
    </Layout>
  );
};

export default CaseStudyTemplate;
